import { useDisclosure } from '@chakra-ui/react'
import { usePreschool } from 'providers/PreschoolProvider'
import { useUser } from 'providers/UserProvider'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FollowupService } from 'services/followupService'
import { Option } from 'react-dropdown'
import { useAsync } from 'react-use'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { Followup, Task } from 'data-models'
import { FollowupStatus } from 'data-models/enums'
import { useOrganizationDetails } from 'services/organizationService'
import { useTaskService } from 'services/taskService'
import i18n from 'i18next';

export const useWelcome = () => {
  const {
    preschool,
    preschools,
    setPreschool,
    loading,
    departmentId,
    setDepartmentId,
  } = usePreschool()
  const [preschoolOptions, setPreschoolOptions] = useState<Option[]>([])
  const [FollowUpOptions, setFollowUpOptions] = useState<Option[]>([])
  const { user } = useUser()
  const { dispatch, setFollowupInfo, setOriginalTasks } = useFollowUp()
  const [selectedFollowupId, setSelectedFollowupId] = useState<string>('')

  // const [tasks, setTasks] = useState<Task[]>([])


  
  
 

  let { tasks } =  useTaskService()



  const followupsResponse = useAsync(() =>
    FollowupService.getFollowups(user.organizationId),
  )
  const [followupsList, setFollowupsList] = useState<Followup[]>([])


  useEffect(() => {
    if (followupsResponse && followupsResponse.value) {
      setFollowupsList(
        followupsResponse.value.filter(f => f.status === FollowupStatus.active),
      )
    }
    goToChildInformation()
  }, [followupsResponse])

  const { isOpen, onToggle } = useDisclosure()

  const goToChildInformation = () => {
    if (selectedFollowupId) {
      const followupList = followupsResponse.value
      const selectedFollowup = followupList?.find(
        followup => followup.id === selectedFollowupId,
      )
      if (selectedFollowup) {

        if (
          selectedFollowup.id &&
          selectedFollowup.name &&
          selectedFollowup.tasks &&
          selectedFollowup.tasks.length > 0
        ) {
          const followupInfo = {
            id: selectedFollowup.id,
            followupName: selectedFollowup.name,
          }
          setFollowupInfo(followupInfo)
          const taskArr = tasks
            .filter(task => selectedFollowup.taskIds?.includes(task.id))
            .sort((taskOne, taskTwo) => {
              return taskOne.orderIndex - taskTwo.orderIndex
            })
          selectedFollowup
          const taskArrIndexed = taskArr?.map((task, index) => {
            return { ...task, orderIndex: index + 1, isSkipped: false, step: 0 ,audioRecordings:[]}
          })


          setOriginalTasks(taskArrIndexed)

          dispatch({
            type: QUESTION_ACTIONS.SET_QUESTIONS,
            payload: taskArrIndexed,
          })

          dispatch({
            type: QUESTION_ACTIONS.SET_ORIGINAL_TASKS,
            payload: taskArrIndexed,
          })
          dispatch({
            type: QUESTION_ACTIONS.UPDATE_CURRENT_TASK,
          })
          // dispatch({
          //   type: QUESTION_ACTIONS.RESET_TASKS
          // })
        }
      }
    }


  }

  const handleNext = () => {
    if (followupsList?.length === 1) {
      goToChildInformation()
    } else {
      onToggle()
    }
  }

  const onPreschoolSelect = (selected: Option) => {
    const ps = preschools.find(x => selected.value === x.id.toString())
    if (ps) setPreschool(ps)
    setDepartmentId(ps?.departments[0]?.id || '')
  }

  const handleSelectDepartment = (departmentOption: Option) => {
    setDepartmentId(departmentOption.value)
  }

  useEffect(() => {
    setPreschoolOptions(
      preschools?.map(ps => ({
        value: ps.id.toString(),
        label: ps.name,
      })),
    )
  }, [preschools])
  useEffect(() => {
    setFollowUpOptions(
      followupsList?.map(followUpItem => {
        const startDate = new Date(followUpItem.startDate);
        const endDate = new Date(followUpItem.endDate);
        const startDay = startDate.getDate();
        const startMonth = (
          i18n.language === 'en' ? startDate.toLocaleString('en-US', { month: 'long' }) :
          i18n.language === 'se' ? startDate.toLocaleString('sv-SE', { month: 'long' }) :
          i18n.language === 'de' ? startDate.toLocaleString('de-DE', { month: 'long' }) :
          startDate.toLocaleString('en-US', { month: 'long' }) // Default to English if no match
        ).toLocaleLowerCase();
        
        const endDay = endDate.getDate();
        
        const endMonth = (
          i18n.language === 'en' ? endDate.toLocaleString('en-US', { month: 'long' }) :
          i18n.language === 'se' ? endDate.toLocaleString('sv-SE', { month: 'long' }) :
          i18n.language === 'de' ? endDate.toLocaleString('de-DE', { month: 'long' }) :
          endDate.toLocaleString('en-US', { month: 'long' }) // Default to English if no match
        ).toLocaleLowerCase();
        const capitalizedFollowupName = followUpItem.name.charAt(0).toUpperCase() + followUpItem.name.slice(1); // Capitalize first letter of ps.name
        const label = `(${startDay} ${startMonth} - ${endDay} ${endMonth})`;
  
        return {
          value: followUpItem.id.toString(),
          label:`${capitalizedFollowupName} ${label}   ${followUpItem.description}`
        };
      })
    );
  }, [followupsList, i18n.language]);
  
  const {


    user: { organizationId },
  } = useUser()
  const { organization } = useOrganizationDetails(organizationId)

  return {
    organization, FollowUpOptions,
    preschool,
    loading,
    preschoolOptions,
    isOpen,
    onToggle,
    handleNext,
    onPreschoolSelect,
    goToChildInformation,
    selectedFollowupId,
    setSelectedFollowupId,
    followupsList,
    departmentId,
    handleSelectDepartment,
  }
}
