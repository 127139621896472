import React, { useEffect } from 'react'
import {
  Box,
  Text,
  VStack,
  Center,
  Spinner,
  useMediaQuery
} from '@chakra-ui/react'
import { useAsync } from 'react-use'
import { Redirect, Route, Switch } from 'react-router-dom'
import ReleaseNotes from 'ReleaseNotes'
import Welcome from './Welcome'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { FullscreenProvider } from 'providers/FullscreenProvider'
import ChildInformation from './FollowUp/ChildInformation'
import { TasksContainer } from './FollowUp/TasksContainer'
import FollowUpFinish from './FollowUp/FollowUpFinish'
import NoFollowUp from './NoFollowUp'
import FollowUpForbidden from './FollowUpForbidden'
import axiosAPIInstance from 'services/api'
import { useUser } from 'providers/UserProvider'
import { TeacherComment } from './TeacherComment'
import { Followup, Task } from 'data-models'
import { Footer } from './Footer'
import { Header } from './Header'
import { useTaskService } from 'services/taskService'
import { useTranslation } from 'react-i18next'
import OrientationModal from './OrientationModal'
import ReleaseNotesHistory from './ReleaseNotesHistory'

export default function Authenticated() {

  const { dispatch, setFollowupInfo, setOriginalTasks } = useFollowUp()
  const { user } = useUser()
  const { organizationId } = user

  const { tasks } = useTaskService()
  
  const { t,i18n } = useTranslation()

  const { loading, error, value } = useAsync(async () => {
    try{
      console.log('Fetching followup data...')
      const res = await axiosAPIInstance.get(
        `/followups?organizationId=${organizationId}`,
      )
      console.log('Response received:', res)
      if (res.data) {
        const activeFollowup = res.data[0] as Followup
        if (
          activeFollowup.id &&
          activeFollowup.name &&
          activeFollowup.tasks &&
          activeFollowup.tasks.length > 0
        ) {
          const followupInfo = {
            id: activeFollowup.id,
            followupName: activeFollowup.name,
          }
          setFollowupInfo(followupInfo)
          const taskArr = tasks.filter(task =>
            activeFollowup.taskIds?.includes(task.id),
          )
          
          taskArr.sort((taskOne, taskTwo) => {
            return taskOne.orderIndex - taskTwo.orderIndex
          })
  
          const taskArrIndexed: Task[] = taskArr.map((task, index) => {
            return { ...task, orderIndex: index + 1, isSkipped: false, step: 0 ,audioRecordings:[]}
          })
  
          setOriginalTasks(taskArrIndexed)
  
          dispatch({
            type: QUESTION_ACTIONS.SET_QUESTIONS,
            payload: taskArrIndexed,
          })
  
          dispatch({
            type: QUESTION_ACTIONS.SET_ORIGINAL_TASKS,
            payload: taskArrIndexed,
          })
  
          dispatch({
            type: QUESTION_ACTIONS.UPDATE_CURRENT_TASK,
          })
  
          return activeFollowup
        } else return undefined
      } else {
        return undefined
      }
    }
   
    catch (err) {
      console.error('Error when fetching followup: ', err)
      throw err
    }

   
  })

  useEffect(() => {
    if (error) {
      console.log('Error when fetching followup: ', error)
    }
  }, [error])

  const [isSmallScreen] = useMediaQuery('(max-height: 900px)')

  const currentPath = location.pathname


  return loading ? (
    <Center h='full' w='full'>
      <VStack spacing='5'>
        <Spinner color='white' size='xl' />
        <Text color='white' mb='14' mt='7' fontSize='large' fontWeight='light'>
  {currentPath !== '/release-notes' ? t('Fetching_FollowUp_Text') : ''}
</Text>

      </VStack>
    </Center>
  ) : value !== undefined && tasks.length > 0 ? (
    <VStack px='6' w='full' h='full' position='relative'>
      <Header />

      {/* Task body */}
       <Box w='full' px='3' h='85vh' maxH={isSmallScreen ? '75%' : '85%'} className='router-wrapper' id='router-wrapper-id'>
        <FullscreenProvider>
        <ReleaseNotes />
          <Switch>
            <Route path='/welcome' component={Welcome} />
            <Route path='/no-follow-up' component={NoFollowUp} />
            {/* <Route path='/child-information' component={ChildInformation} /> */}
            <Route path='/tasks' component={TasksContainer} />
            <Route path='/follow-up-finish' component={FollowUpFinish} />
            <Route path='/follow-up-comment' component={TeacherComment} />
            <Route path='/release-notes' component={ReleaseNotesHistory} />
            <Route path='/' render={() => <Redirect to='/welcome' />} />
          </Switch>
        </FullscreenProvider>
      </Box>
      <OrientationModal />
      <Footer />
    </VStack>
  ) : (
    <FollowUpForbidden />
  )
}
