import React, { useState, useEffect } from 'react'
import { HStack } from '@chakra-ui/react'
import { getDaysInMonth, setDate, setMonth, setYear, subYears } from 'date-fns'
import Dropdown, { Option } from 'react-dropdown'
import { range, toNumber } from 'lodash-es'
import { useTranslation } from 'react-i18next'
const monthNameMapping = {
  0: 'Januari',
  1: 'Februari',
  2: 'Mars',
  3: 'April',
  4: 'Maj',
  5: 'Juni',
  6: 'Juli',
  7: 'Augusti',
  8: 'September',
  9: 'Oktober',
  10: 'November',
  11: 'December',
}

export default function SelectDatePickerCmp(props: {
  onValueChange?: Function
  IsSelectedChange: Function
}) {
  // Define valid age interval (usually 2-5)
  // TODO: find a way to improve handling of new year start cases (2-6)
  const maxAge = 6
  const minAge = 2
  const currentDate = new Date()
  const maxDate = subYears(currentDate, minAge)
  const minDate = subYears(currentDate, maxAge+1) // Min birth date
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()
  const minYear = currentYear - maxAge
  const maxYear = currentYear - minAge
  const [IsYearSelected, setIsYearSelected] = useState(false)
  const [IsMonthSelected, setIsMonthSelected] = useState(false)
  const [IsDaySelected, setIsDaySelected] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date>(maxDate)

  const years = range(minYear, maxYear + 1).reverse()
  // const months = range(0, 12)
  // const days = range(1, getDaysInMonth(selectedDate) + 1)


  // Dynamically calculate month options
const months = range(0, 12).filter(month => {
  const potentialDate = setMonth(setYear(new Date(), selectedDate.getFullYear()), month)
  if (selectedDate.getFullYear() === maxDate.getFullYear()) {
    return month <= maxDate.getMonth()
  } else if (selectedDate.getFullYear() === minDate.getFullYear()) {
    return month >= minDate.getMonth()
  }
  return true
})

// Dynamically calculate day options
const days = range(1, getDaysInMonth(selectedDate) + 1).filter(day => {
  const potentialDate = setDate(selectedDate, day)
  if (
    selectedDate.getFullYear() === maxDate.getFullYear() &&
    selectedDate.getMonth() === maxDate.getMonth()
  ) {
    return day <= maxDate.getDate()
  } else if (
    selectedDate.getFullYear() === minDate.getFullYear() &&
    selectedDate.getMonth() === minDate.getMonth()
  ) {
    return day >= minDate.getDate()
  }
  return true
})


  const monthOptions: Option[] = months.map(
    m => ({ label: monthNameMapping[m], value: m.toString() } as Option),
  )

  useEffect(() => {
    if (props.onValueChange) {
      if (IsYearSelected && IsMonthSelected && IsDaySelected) {
        props.IsSelectedChange(true)
      }
      props.onValueChange(selectedDate.toISOString().substr(0, 10))
    }
  }, [selectedDate])

  const updateYear = (yearOpt: Option) => {
    setIsYearSelected(true)
    setSelectedDate(setYear(selectedDate, toNumber(yearOpt.value)))
  }

  const updateMonth = (monthOpt: Option) => {
    setIsMonthSelected(true)
    setSelectedDate(setMonth(selectedDate, toNumber(monthOpt.value)))
  }

  const updateDay = (dayOpt: Option) => {
    setIsDaySelected(true)
    setSelectedDate(setDate(selectedDate, toNumber(dayOpt.value)))
  }

  return (
    <HStack spacing='5' w='100%' justifyContent='space-between'>
      <Dropdown
        options={years.map(y => ({ value: y.toString(), label: y }))}
        onChange={updateYear}
        // value={getYear(selectedDate).toString()}
        controlClassName={`custom-control w-date-picker ${IsYearSelected ? '' : 'addError'}`}
        arrowClassName='custom-arrow'
        menuClassName='custom-menu'
        placeholder={t('Year_Select_Box')}
        placeholderClassName='custom-placeholder'
      />
      <Dropdown
        options={monthOptions}
        onChange={updateMonth}
        // value={getMonth(selectedDate).toString()}
        controlClassName={`custom-control w-date-picker ${IsMonthSelected ? '' : 'addError'}`}
        arrowClassName='custom-arrow'
        menuClassName='custom-menu'
        placeholder={t('Month_Select_Box')}
        placeholderClassName='custom-placeholder'
      />
      <Dropdown
        options={days.map(d => ({ value: d.toString(), label: d }))}
        onChange={updateDay}
        // value={getDate(selectedDate).toString()}
        controlClassName={`custom-control w-date-picker ${IsDaySelected ? '' : 'addError'}`}
        arrowClassName='custom-arrow'
        menuClassName='custom-menu'
        placeholder={t('Day_Select_Box')}
        placeholderClassName='custom-placeholder'
      />
    </HStack>
  )
}
