import {
  ImagePlacementAnswer,
  ImagePlacementTask,
  Task,
  TaskType,
} from 'data-models/task-models'
import React, {
  useContext,
  createContext,
  useReducer,
  Dispatch,
  useState,
} from 'react'
import { Child } from 'data-models/child'
import { FollowupInfo } from 'data-models/followupinfo'

export enum QUESTION_ACTIONS {
  SET_QUESTIONS,
  UDPATE_QUESTION,
  UPDATE_ANSWERS,
  UPDATE_IMAGE_PLACEMENT_ANSWER,
  UPDATE_IMAGE_SORTING_ANSWER,
  UPDATE_CURRENT_TASK,
  NAV_NEXT,
  NAV_PREV,
  NAV_TO_QUESTION,
  SET_CURRENT_STEP,
  TOGGLE_SKIP_CURRENT_TASK,
  SKIP_CURRENT_TASK,
  UNSKIP_CURRENT_TASK,
  RESET_CURRENT_TASK,
  SET_ORIGINAL_TASKS,
  RESET_TASKS,
  UPDATE_LANGUAGE_ANALYSIS_TASK,
  RESET_AUDIO_RECORDINGS,
  UPDATE_LANGUAGE_ANALYSIS_SKIPTASK,
  NAV_NEXT_QUESTION
}

export interface TaskAction {
  type: QUESTION_ACTIONS
  payload?: any
}

export interface TasksState {
  originalTasks: Task[]
  tasks: Task[]
  currentTaskIndex: number
}

const initialState: TasksState = {
  originalTasks: [],
  tasks: [],
  currentTaskIndex: 0,
}

export const computeMaxStep = (type: TaskType) => {
  const singleStepTypes = [TaskType.ImagePlacement, TaskType.ImageSorting, TaskType.LanguageAnalysis]

  const threeStepTypes = [TaskType.VideoMulti]

  if (singleStepTypes.includes(type)) return 0
  if (threeStepTypes.includes(type)) return 2
  return 1
}

export function reducer(state: TasksState, action: TaskAction): TasksState {
  const updateCurrentTask = (newTask: Partial<Task>): TasksState => (
    {
    ...state,
    tasks: state.tasks.map(q =>
     {
      const res = q.id === state.tasks[state.currentTaskIndex].id
        ? { ...q, ...newTask }
        : q
      return res
     }
    ),
  })

  const updateTask = ({
    newTask,
    taskIndex,
  }: {
    newTask: Partial<Task>
    taskIndex: number
  }): TasksState => ({
    ...state,
    tasks: state.tasks.map(q =>
      q.id === state.tasks[taskIndex].id ? { ...q, ...newTask } : q,
    ),
  })

  const updateCurrentIndex = (newIndex: number): TasksState => ({
    ...state,
    currentTaskIndex: state.tasks[newIndex] ? newIndex : state.currentTaskIndex,
  })

  const goToNextStep = (): TasksState => {
    const { currentTaskIndex, tasks } = state
    const currentTask = tasks[currentTaskIndex]
    const nextTask = tasks[currentTaskIndex + 1]

    //Step counting begins with 0, not 1. It makes indexing easier.
    const { step, type } = currentTask
    const maxStep = computeMaxStep(type)
    const newTaskIndex =
      step === maxStep ? currentTaskIndex + 1 : currentTaskIndex

    return {
      ...updateTask({
        newTask: {
          ...(step === maxStep ? nextTask : currentTask),
          step: step === maxStep ? 0 : step + 1,
        },
        taskIndex: newTaskIndex,
      }),
      currentTaskIndex: newTaskIndex,
    }
  }

  const goToNextQuestion = (): TasksState => {
    const { currentTaskIndex, tasks } = state;
  
    // If no tasks, return the current state
    if (!tasks || tasks.length === 0) return state;
  
    const currentTask = tasks[currentTaskIndex];
    const nextTask = tasks[currentTaskIndex + 1];
  
    const { step, type } = currentTask;
    const maxStep = computeMaxStep(type);
  
    let skipSteps = 1; // Default: skip one step
    if (maxStep === 1) skipSteps = 1; // For maxStep === 1, skip two steps
  
    // Determine if it's the last question
    const isLastQuestion = currentTaskIndex === tasks.length - 1;
    // Calculate the next task index
    const newTaskIndex = isLastQuestion
      ? currentTaskIndex + 1 // If it's the last task, skip only one step
      : currentTaskIndex + skipSteps;

    // Ensure we don't exceed the tasks array
    const safeNewTaskIndex = Math.min(newTaskIndex, tasks.length-1);
  
    // Return updated state
    return {
      ...state,
      currentTaskIndex: safeNewTaskIndex,
    };
  };



  const goToPreviousStep = (): TasksState => {
    const { currentTaskIndex, tasks } = state
    const currentTask = tasks[currentTaskIndex]

    //Step counting begins with 0, not 1. It makes indexing easier.
    const { step } = currentTask

    const newTaskIndex = step === 0 ? currentTaskIndex - 1 : currentTaskIndex

    const previousTask = tasks[currentTaskIndex - 1]
    const previousTaskMaxStep = computeMaxStep(previousTask?.type)

    return {
      ...updateTask({
        newTask: {
          ...(step === 0 ? previousTask : currentTask),
          step: step === 0 ? previousTaskMaxStep : step - 1,
        },
        taskIndex: newTaskIndex,
      }),
      currentTaskIndex: newTaskIndex,
    }
  }


  switch (action.type) {
    case QUESTION_ACTIONS.RESET_CURRENT_TASK: {
      const { currentTaskIndex, originalTasks, tasks } = state
      const skippedReason = action.payload 

    

      if(skippedReason === '' || skippedReason === undefined ){
        return updateTask({
          newTask: {
            ...originalTasks[currentTaskIndex],
            isSkipped: !tasks[currentTaskIndex].isSkipped,
            skipReason: ''
          },
          taskIndex: currentTaskIndex,
        })
      }
      else {
        return updateTask({
          newTask: {
            ...originalTasks[currentTaskIndex],
            isSkipped: tasks[currentTaskIndex].isSkipped,
            skipReason: skippedReason
          },
          taskIndex: currentTaskIndex,
        })
      }
   
    }

    case QUESTION_ACTIONS.TOGGLE_SKIP_CURRENT_TASK: {
      const { tasks, currentTaskIndex } = state

      return updateCurrentTask({
        isSkipped: !tasks[currentTaskIndex].isSkipped,
      })
    }

    case QUESTION_ACTIONS.SKIP_CURRENT_TASK: {
      return updateCurrentTask({
        isSkipped: true,
      })
    }

    case QUESTION_ACTIONS.SET_CURRENT_STEP: {
      return updateCurrentTask({
        step: action.payload,
      })
    }

    case QUESTION_ACTIONS.UNSKIP_CURRENT_TASK: {
      return updateCurrentTask({
        isSkipped: false,
        skipReason: ''
      })
    }

    case QUESTION_ACTIONS.SET_ORIGINAL_TASKS:
      return { ...state, currentTaskIndex: 0, originalTasks: action.payload }

    case QUESTION_ACTIONS.SET_QUESTIONS:
      return { ...state, currentTaskIndex: 0, tasks: action.payload }

    case QUESTION_ACTIONS.UDPATE_QUESTION:
      return updateCurrentTask({ ...action.payload })

    case QUESTION_ACTIONS.UPDATE_ANSWERS:
      return updateCurrentTask({
        answerOptions: action.payload,
      })

    case QUESTION_ACTIONS.UPDATE_IMAGE_PLACEMENT_ANSWER: {
      const { answerOptions } = state.tasks[
        state.currentTaskIndex
      ] as ImagePlacementTask

      const newAnswer = action.payload as ImagePlacementAnswer
      const newAnswers = answerOptions.map(a => {
        // update moved item position
        if (a.imageUrl === newAnswer.imageUrl) {
          return newAnswer
        } else if (a.placedAtPoint === newAnswer.placedAtPoint) {
          // if placement point was used, move previous back to options
          return { ...a, placedAtPoint: null }
        } else {
          return a
        }
      })

      return updateCurrentTask({ answerOptions: newAnswers })
    }

    case QUESTION_ACTIONS.UPDATE_IMAGE_SORTING_ANSWER: {
      const { answerOptions } = state.tasks[
        state.currentTaskIndex
      ] as ImagePlacementTask

      const { placedAtPoint, index } = action.payload
      const newAnswers = answerOptions.map(answer => ({
        ...answer,
        placedAtPoint:
          answer.placedAtPoint === placedAtPoint ? null : answer.placedAtPoint,
      }))
      newAnswers[index].placedAtPoint = placedAtPoint

      return updateCurrentTask({ answerOptions: newAnswers })
    }

    case QUESTION_ACTIONS.NAV_NEXT:
      return goToNextStep()

    case QUESTION_ACTIONS.NAV_PREV:
      return goToPreviousStep()

      case QUESTION_ACTIONS.NAV_NEXT_QUESTION:
        return goToNextQuestion()

    case QUESTION_ACTIONS.NAV_TO_QUESTION: {
      const task = action.payload as Task
      const idx = state.tasks.indexOf(task)
      return updateCurrentIndex(idx)
    }
    case QUESTION_ACTIONS.UPDATE_CURRENT_TASK: {
      return updateCurrentTask({ audioRecordings:[]})
    }
    case QUESTION_ACTIONS.UPDATE_LANGUAGE_ANALYSIS_TASK: {
      const updatedTask: Partial<Task> = { audioRecorded: true };
      return updateCurrentTask(updatedTask);
    }
    case QUESTION_ACTIONS.UPDATE_LANGUAGE_ANALYSIS_SKIPTASK: {
      const updatedTask: Partial<Task> = { audioRecordings: [action.payload] };
      return updateCurrentTask(updatedTask);
    }
  
    default:
      throw new Error('Unknown action dispatched!')
  }
}

const FollowUpContext = createContext<{
  tasks: Task[]
  currentTask: Task
  currentTaskIndex: number
  dispatch: Dispatch<TaskAction>
  child: Child
  setChild: (child: Child) => void
  followupInfo: FollowupInfo
  setFollowupInfo: (newFollowupInfo: FollowupInfo) => void
  originalTasks: Task[]
  setOriginalTasks: (originalTasks: Task[]) => void
  ownComment: string
  setOwnComment: (comment: string) => void
  groupComment: string
  setGroupComment: (comment: string) => void
} | null>(null)

function FollowUpProvider(props: { children: React.ReactNode }) {


  const [{ currentTaskIndex, tasks }, dispatch] = useReducer(
    reducer,
    initialState,
  )

  const currentTask = tasks[currentTaskIndex]
  const [child, setChild] = useState({} as Child)
  const [followupInfo, setFollowupInfo] = useState({} as FollowupInfo)
  const [originalTasks, setOriginalTasks] = useState([] as Task[])
  const [ownComment, setOwnComment] = useState<string>('')
  const [groupComment, setGroupComment] = useState<string>('')
  

  const value = {
    tasks,
    child,
    dispatch,
    setChild,
    ownComment,
    currentTask,
    groupComment,
    followupInfo,
    setOwnComment,
    originalTasks,
    setFollowupInfo,
    setGroupComment,
    currentTaskIndex,
    setOriginalTasks,
  }

  return (
    <FollowUpContext.Provider value={value}>
      {props.children}
    </FollowUpContext.Provider>
  )
}

function useFollowUp() {
  const ctx = useContext(FollowUpContext)
  if (ctx === null) throw new Error('hook must be used inside its provider')
  return ctx
}

export { FollowUpProvider, useFollowUp }
