import React from 'react'
import { useFullscreen } from 'providers/FullscreenProvider'
import { CheckboxAnswer, VideoTask } from 'data-models/task-models'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { useState, useEffect } from 'react'
import {
  Tab,
  Tabs,
  Flex,
  VStack,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react'
import CheckboxAnswerGroup from 'common/CheckboxAnswerGroup/CheckboxAnswerGroup'
import VideoPlayer from './VideoPlayer'
import { apiBase } from 'services/config'
import { TaskHeading } from '../Common/TaskHeading'
import { TaskAdvice } from '../Common'
import { useTranslation } from 'react-i18next'
export default function VideoTaskCmp() {
  const { isFullscreen } = useFullscreen()
  const { t } = useTranslation()
  const { currentTask, dispatch } = useFollowUp()
  const task = currentTask as VideoTask

  useEffect(() => {
    // Reset video on component mount
    setVideoKey((prevKey) => prevKey + 1);
  }, []); // Empty dependency array ensures this runs only on mount

  
  const onAnswerChange = (answers: CheckboxAnswer[]) => {
    dispatch({ type: QUESTION_ACTIONS.UPDATE_ANSWERS, payload: answers })
    dispatch({ type: QUESTION_ACTIONS.UNSKIP_CURRENT_TASK })
  }

  const onChangeTab = (index: number) => {
    dispatch({ type: QUESTION_ACTIONS.SET_CURRENT_STEP, payload: index })
  
  }

  const [videoKey, setVideoKey] = useState(0)
  // check what language video is selected in the dropdown
  const [selectedVideoLanguage, setSelectedVideoLanguage] = useState('swedish')

  useEffect(() => {
    console.log(selectedVideoLanguage) // Log the selected video language
  }, [selectedVideoLanguage])

  const handleVideoLanguageChange = lang => {
    setSelectedVideoLanguage(lang)
  }

  const getVideoSrc = () => {
    // for video tasks, when language other than swedish is selected, we remove -SE from the url and replace it with -EN.
    // const urlParts = task.videoUrl.split('-');
    let languageSuffix = ''
    switch (selectedVideoLanguage) {
      case 'arabic':
        languageSuffix = 'AR'
        break
      case 'danish':
        languageSuffix = 'DA'
        break
      case 'german':
        languageSuffix = 'DE'
        break
      case 'english':
        languageSuffix = 'EN'
        break
      case 'finnish':
        languageSuffix = 'FI'
        break
      case 'french':
        languageSuffix = 'FR'
        break
      case 'norwegian':
        languageSuffix = 'NO'
        break
      case 'italian':
        languageSuffix = 'IT'
        break
      default:
        languageSuffix = 'SE'
        break
    }

    const hyphenIndex = task.videoUrl.lastIndexOf('-')
    const baseUrl = task.videoUrl.substring(0, hyphenIndex)
    return `${apiBase}${baseUrl}-${languageSuffix}.mp4`
    // }
  }

  return (
    <VStack h='full' w='full'>
      <VStack p='5' w='full' h='full' align='center'>
        <TaskHeading
          description={task.description}
          subtitle={task.subtitle}
          headingTitle={task.title}
        />
        <Tabs
          h='full'
          w='full'
          pos='relative'
          variant='unstyled'
          onChange={onChangeTab}
          index={currentTask.step}
        >
          <TabList pb='4' pt='3'>
            <Flex
              h='0'
              w='full'
              pos='absolute'
              alignSelf='center'
              justifySelf='center'
              borderTop='1px solid #2A0F74'
              zIndex={0}
            />
            <Tab
              px={4}
              py={2}
              mr={6}
              zIndex={1}
              border='1px solid #2A0F74'
              fontSize='xs'
              borderRadius='8px'
              bgColor='#6c40f6'
              _selected={{ bgColor: '#2A0F74', fontWeight: '700' }}
              _focus={{ boxShadow: 'none' }}
            >
              <span style={{ opacity: 0.5, paddingRight: '10px' }}>
                {t('Step')} 1
              </span>
              {t('Explain_Scenario_Title')}
            </Tab>
            <Tab
              px={4}
              py={2}
              zIndex={10}
              border='1px solid #2A0F74'
              bgColor='#6C40F6'
              fontSize='xs'
              borderRadius='8px'
              _selected={{ bgColor: '#2A0F74', fontWeight: '700' }}
              _focus={{ boxShadow: 'none' }}
            >
              <span style={{ opacity: 0.5, paddingRight: '10px' }}>
                {t('Step')} 2
              </span>
              {t('Mark_All_Options_Text')}
            </Tab>
          </TabList>

          <TabPanels h='full' w='full' py={0}>
            <TabPanel h='full' w='full'>
              <VStack
                w='full'
                h='100%'
                minH='0'
                align='center'
                spacing={0}
                justifyContent='space-between'
              >
                <VStack w='full' h='full' justify='center' align='center'>
                  <VideoPlayer
                    key={videoKey} // Change key to force remount
                    isFullscreen={isFullscreen}
                    videoSrc={getVideoSrc()}
                    onVideoLanguageChange={handleVideoLanguageChange}
                  />
                </VStack>

                <TaskAdvice instructions={task.taskInstructions} />
              </VStack>
            </TabPanel>
            <TabPanel w='full' h='full' maxH='full'>
              <VStack w='full' h='full'>
                <Flex
                  h='full'
                  w='full'
                  flex='1'
                  align='center'
                  direction='column'
                >
                  <CheckboxAnswerGroup
                    options={task.answerOptions}
                    onChange={onAnswerChange}
                  />
                </Flex>
                <TaskAdvice
                  instructions={task.answerInstructions}
                  marginTop='auto !important'
                />
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </VStack>
  )
}
