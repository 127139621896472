import React, { useEffect, useState } from 'react'
import { Button, Image } from '@chakra-ui/react'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { useDrag } from 'react-dnd'
import { ImagePlacementAnswer, PlacementPoint } from 'data-models/task-models'
import { apiBase } from 'services/config'

export default function ImagePlacementItem(props: {
  ipa: ImagePlacementAnswer
  reset?: boolean
}) {
  const { dispatch } = useFollowUp()
 
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, drag] = useDrag(
    () => ({
      type: 'image',
      item: { image: apiBase + props.ipa.imageUrl },
      end: (_, monitor) => {
        const pp = monitor.getDropResult<PlacementPoint>()
        const newAnswer = { ...props.ipa, placedAtPoint: pp?.id || null }
        dispatch({
          type: QUESTION_ACTIONS.UPDATE_IMAGE_PLACEMENT_ANSWER,
          payload: newAnswer,
        })
        dispatch({ type: QUESTION_ACTIONS.UNSKIP_CURRENT_TASK })
      },
    }),
    [props.ipa],
  )
  useEffect(() => {
    if (props.reset) {
      dispatch({
        type: QUESTION_ACTIONS.UPDATE_IMAGE_PLACEMENT_ANSWER,
        payload: { ...props.ipa, placedAtPoint: null },
      });
    }
  }, [props.reset, dispatch, props.ipa]);

  return (
    <>
     <Image
      src={apiBase + props.ipa.imageUrl}
      ref={drag}
      fit='cover'
      maxW='unset'
      pos='absolute'
      zIndex='0'
      gridGap='8'
      cursor='pointer'
    />
  
    </>
   
  )
}
