import { Button, Center, Flex, Grid, GridItem, useMediaQuery } from '@chakra-ui/react'
import ImagePlacementItem from './ImagePlacementItem'
import { ImageSortingTask, PlacementPoint } from 'data-models/task-models'
import { useFollowUp, QUESTION_ACTIONS } from 'providers/FollowUpProvider'
import ImagePlacementPoint from './ImagePlacementPoint'
import Ellipse from '../../../../../assets/images/Ellipse.svg'
import Square from '../../../../../assets/images/Square.svg'
import Triangle from '../../../../../assets/images/Triangle.svg'
import Rectangle from '../../../../../assets/images/Rectangle.svg'
import { RepeatIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
interface ImagePlacementImagesContainerProps {
  images: string[],
  resetCards?: boolean
  handleReset?: () => void
// onDraggedItem?: React.Dispatch<React.SetStateAction<string>>
}

export const ImagePlacementImagesContainer = ({
  images,
  resetCards,
  handleReset
//onDraggedItem
}: ImagePlacementImagesContainerProps) => {
  const { currentTask, dispatch } = useFollowUp()
  const task = currentTask as ImageSortingTask
const { t } = useTranslation()
  const gridColumnNumber = task.images.length

//we have another array for count numbers task, as the image answer includes the number 3,4,5,6,9 not like the normal ones - 1,2,3,4,5
  const countNumbersTask = (currentTask.title[1] === 'Which number matches the number of dots on the die? Drag each dice to its corresponding number.');

  const sortShapesTask = (currentTask.name[1] === 'Shapes' && currentTask.knowledge[1] === 'Mathematics Level 1');

  const sortShapesTaskLevel2 = (currentTask.name[1] === 'Shapes' && currentTask.knowledge[1] === 'Mathematics Level 2');


  const countNumbersTaskArray = [3,4,5,6,9] ;

  const sortShapesTaskArray = [Ellipse,Ellipse,Ellipse,Square,Square,Square]

  const sortShapesLevel2Array = [Ellipse,Ellipse,Square,Square,Triangle,Triangle,Rectangle,Rectangle]



  const changePlacement = (newXY: PlacementPoint, draggedItem: string) => {

  
    const movedItemIndex = task.placementPoints.findIndex(
      pp => pp.id === newXY.id,
    )

 
    //if(currentTask.name[1] == 'Sort shapes'){
 
//if(onDraggedItem)
//  onDraggedItem(draggedItem);



    //   const newXYSort = newXY.id.slice(0,-1)
    //   const draggedItemSort = draggedItem.slice(0,-1)
    //   newXYSort === draggedItemSort ? 
    //   dispatch({
    //     type: QUESTION_ACTIONS.UPDATE_IMAGE_SORTING_ANSWER,
    //     payload: { placedAtPoint: draggedItemSort, index: movedItemIndex },
    //   })
    //   :
    //   dispatch({
    //     type: QUESTION_ACTIONS.UPDATE_IMAGE_SORTING_ANSWER,
    //     payload: { placedAtPoint: draggedItem, index: movedItemIndex },
    //   })
    // }
    // else{
     
      dispatch({
        type: QUESTION_ACTIONS.UPDATE_IMAGE_SORTING_ANSWER,
        payload: { placedAtPoint: draggedItem, index: movedItemIndex },
      })
  //  }
    
    dispatch({ type: QUESTION_ACTIONS.UNSKIP_CURRENT_TASK })
  }

  const [isSmallScreen] = useMediaQuery('(max-height: 900px)')



  return (
    <Flex flexDir={'row'} alignContent={'center'} justifyContent={'center'} m='0 auto'>
   
    <Grid
      h='full'
      w='full'
      gridTemplateColumns={`repeat(${gridColumnNumber}, minmax(50px, ${
        isSmallScreen ? '150px' : '200px'
      }))`}
      gridTemplateRows={`repeat(2, minmax(50px, ${
        isSmallScreen ? '150px' : '200px'
      }))`}
      gridGap={'5'}
      justifyContent='center'
      alignContent='center'
    >
      {images.map((ao, index) => (
        <GridItem
          key={`${ao}_without_image ${index} ${currentTask.id}`}
          gridRow={1}
        >
          {ao !== '' ? (
            <Center
              bgColor='white'
              width='full'
              height='full'
              borderRadius='2xl'
            >
              <ImagePlacementItem
                key={`${ao}_without_image ${index} image_placement_comp`}
                ipa={ao}
              />
            </Center>
          ) : (
            <Center
              width='full'
              height='full'
              borderRadius='2xl'
              borderWidth='1px'
              borderStyle='dashed'
              borderColor='white'
              color='rgba(255,255,255,0.25)'
              fontSize='48'
              fontWeight='semibold'
              // bgColor={sortShapesTask ? 'white' : 'transparent'}
            ></Center>
          )}
        </GridItem>
      ))}

      {task.placementPoints.map((pp: any, index) => {
        return (
          <GridItem key={`${pp.id}_${currentTask.id}`} gridRow={2}>
            <ImagePlacementPoint
              key={`${pp.id} image_placement_point`}
              currentPoint={pp}
              onChange={changePlacement}
              text={countNumbersTask 
                ? countNumbersTaskArray[index] 
                : sortShapesTask 
                  ? sortShapesTaskArray[index] 
                  : 
                  sortShapesTaskLevel2
                  ? sortShapesLevel2Array[index] 
                  : index + 1}
              answerIndex={index}
            />
          </GridItem>
        )
      })}
         {/* <Button  py='4'
              px='3'
              ml='auto'
            
              fontSize='18px'
            alignSelf={'end'}
              rightIcon={<RepeatIcon />}
              >{t('Reset_Text')}</Button> */}
                {/* Adding Reset button in the last grid item */}
        <GridItem gridRow={3} gridColumn={gridColumnNumber} display="flex" justifyContent="end" mt='3'>
          <Button
            py='4'
            px='3'
            fontSize='18px'
            rightIcon={<RepeatIcon />}
            onClick={handleReset}
            bg='transparent'
            variant='outline'
          >
            {t('Reset_Text')}
          </Button>
        </GridItem>
    </Grid>
 
    </Flex>
  )
}
