import React, { useState } from 'react'
import { HStack } from '@chakra-ui/layout'
import { HelpToolTip } from 'pages/Authenticated/FollowUp/HelpToolTip'
import { QUESTION_ACTIONS, useFollowUp } from 'providers/FollowUpProvider'
import { Button, Checkbox, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
import SkipModal from './SkipModal'
export const SkipTask = () => {
  const { currentTask, dispatch } = useFollowUp()
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleOnClick = (event) => {
    const isChecked = event.target.checked;
    dispatch({ type: QUESTION_ACTIONS.RESET_CURRENT_TASK})
    if(isChecked === false){
      onOpen()
    }
    else if(isChecked === true){
      setValue('')
    }
  }
 // Prevent HelpToolTip from triggering checkbox selection
 const handleHelpToolTipClick = (event) => {
  event.stopPropagation(); // Prevent the click event from reaching the checkbox
}

  return (
    <>
    <HStack
      // p='2.5'
      cursor='pointer'
      borderRadius='xl'
      w='max-content'
      minW='220px'
      h='fit-content'
      onClick={handleOnClick}
     // justify='center'
      align='center'
      zIndex='950'                            
    >
      <Checkbox
        size='lg'
        onChange={handleOnClick}
        isChecked={currentTask?.isSkipped}  
        
      >
       {t('Child_Not_Participate')}
      </Checkbox>
      <div onClick={handleHelpToolTipClick}>
      <HelpToolTip imgSize={5} image={<InfoOutlineIcon boxSize={5} />} />
      </div>
    </HStack>
    <SkipModal onOpen={onOpen} onClose={onClose} isOpen={isOpen} value={value} setValue={setValue}/>
    </>
     
   
  
  )
}
