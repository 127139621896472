import React from 'react'
import { Redirect } from 'react-router-dom'
import ExpandablePageContainer from 'common/ExpandablePageContainer'
import { Task, TaskType } from 'data-models/task-models'
import { useFollowUp } from 'providers/FollowUpProvider'
import CheckboxTaskCmp from './TaskTypes/CheckboxTask'
import AudioTaskCmp from './TaskTypes/AudioTask/AudioTask'
import ImagePlacementTaskCmp from './TaskTypes/ImagePlacement/ImagePlacementTask'
import { DndProvider, Preview } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import { Image, VStack, Text, Flex } from '@chakra-ui/react'
import ImageSelectionTaskCmp from './TaskTypes/ImageSelectionTask'
import VideoTaskCmp from './TaskTypes/VideoTask/VideoTask'
import VideoMultiTaskCmp from './TaskTypes/VideoTask/VideoMultiTask'
import { ImageSortingTaskCmp } from './TaskTypes/ImageSorting/ImageSortingTask'
import { BlockNavigation } from 'common/BlockNavigation'
import { TaskService } from 'services/taskService'
import { useTranslation } from 'react-i18next'
import LanguageTask from './TaskTypes/LanguageAnalysis/LanguageTask'
const generatePreview = ({ item, style }) => {
  return <Image src={item.image} style={{ ...style, opacity: 0.5 }} />
}

const TaskMapping = (task: Task) => {
  switch (task.type) {
    case TaskType.Checkbox:
      return (
        <CheckboxTaskCmp key={`${task.orderIndex}_${task.type}_${task.id}`} />
      )
    case TaskType.Audio:
      return <AudioTaskCmp key={`${task.orderIndex}_${task.type}_${task.id}`} />
    case TaskType.Video:
      return <VideoTaskCmp key={`${task.orderIndex}_${task.type}_${task.id}`} />
    case TaskType.VideoMulti:
      return (
        <VideoMultiTaskCmp key={`${task.orderIndex}_${task.type}_${task.id}`} />
      )
    case TaskType.ImagePlacement:
      return (
        <DndProvider options={HTML5toTouch}>
          <ImagePlacementTaskCmp
            key={`${task.orderIndex}_${task.type}_${task.id}`}
          />
          <Preview generator={generatePreview} />
        </DndProvider>
      )
    case TaskType.ImageSorting:
      return (
        <DndProvider options={HTML5toTouch}>
          <ImageSortingTaskCmp
            key={`${task.orderIndex}_${task.type}_${task.id}`}
          />
          <Preview generator={generatePreview} />
        </DndProvider>
      )
    
    case TaskType.ImageSelection:
      return (
        <ImageSelectionTaskCmp
          key={`${task.orderIndex}_${task.type}_${task.id}`}
        />
      )
    case TaskType.LanguageAnalysis:
      return (
      
        <LanguageTask
        key={`${task.orderIndex}_${task.type}_${task.id}`}
        />
        
      )
    default:
      break
  }
}

export const TasksContainer = () => {
  const { currentTask, tasks } = useFollowUp()
  const { t } = useTranslation()

  const numberOfCompletedTasks = tasks.reduce((acc, curr) => {
    return acc + (TaskService.taskIsAnswered(curr) ? 1 : 0)
  }, 0)


  return currentTask?.type ? (
    <Flex h='full' w='full'>
      <BlockNavigation
        exemptPaths={['/follow-up-finish', '/follow-up-comment']}
      >
        <VStack w='full' alignItems='flex-start' spacing={5}>
          <Text fontWeight='bold' color='mainBlue.900'>{` ${t(
            'Completion_Done',
          )} ${numberOfCompletedTasks} ${t('Tasks_So_Far_Text')}`}</Text>
          <Text color='mainBlue.900'>
            {t('Cancel_Current_Tracking_Question')}
          </Text>
        </VStack>
      </BlockNavigation>
      <ExpandablePageContainer showFullscreenToggle>
        {TaskMapping(currentTask)}
      </ExpandablePageContainer>
    </Flex>
  ) : (
    <Redirect to='/follow-up' />
  )
}
