import React, { useState } from 'react'
import { ImagePlacementTask } from 'data-models/task-models'
import { useFollowUp } from 'providers/FollowUpProvider'
import { Flex, VStack, Center, useBreakpoint, Button, HStack } from '@chakra-ui/react'
import ImagePlacementBase from './ImagePlacementBase'
import ImagePlacementItems from './ImagePlacementItems'
import { TaskHeading } from '../Common/TaskHeading'
import { TaskAdvice } from '../Common'
import useWindowDimensions from 'common/utils'
import { RepeatIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'

export default function ImagePlacementTaskCmp() {
  const { currentTask } = useFollowUp()
  const task = currentTask as ImagePlacementTask
  const { t } = useTranslation()

  const [reset, setReset] = useState(false);

  const { height } = useWindowDimensions()

  const breakpoint = useBreakpoint() || '2xl'
  const heightBreakpointReached = height <= 700

  const computeWidthByBreakpoint = () => {
    if (heightBreakpointReached) return '20%'

    switch (breakpoint) {
      case 'lg':
        return '100%'
      case 'md':
        return '20%'
      default:
        return 'unset'
    }
  }

  const handleReset = () => {
    setReset(true); // Trigger reset
    setTimeout(() => setReset(false), 0); // Reset `reset` back to false
  };


  return (
    <Flex direction='column' h='full' w='full'>
      <VStack direction='column' align='stretch' p='5' h='full' w='full'>
        <Flex
          flexDir={
            ['lg','xl' ,'2xl'].includes(breakpoint) && !heightBreakpointReached
              ? 'column'
              : 'row'
          }
          minH={'0'}
          h='100%'
          w='100%'
        >
          <TaskHeading
            description={task.description}
            subtitle={task.subtitle}
            headingTitle={task?.title}
            mr='2'
            mb='15px'
            maxW={computeWidthByBreakpoint()}
            h={
              ['lg','xl', '2xl'].includes(breakpoint) && !heightBreakpointReached
                ? 'unset'
                : '100%'
            }
          />

          <Center
            w='full'
            h='full'
            minW='0'
            userSelect='none'
            flex='1'
            gridGap='8'
            minH='0'
            bottom={['xl', '2xl'].includes(breakpoint) ? 'unset' : '-5'}
            pos='relative'
            justifyContent={
              ['lg','xl', '2xl'].includes(breakpoint) ? 'center' : 'flex-start'
            }
          >
            <Flex flexDir={'column'} alignItems={'center'}> 
              <HStack paddingRight={0} >
              <ImagePlacementBase reset={reset}/>
              <ImagePlacementItems />
              </HStack>
              <Button  
               pos={'relative'}
              fontSize='18px'onClick={handleReset}
              width={'fit-content'}
              bg='transparent'
              alignSelf={'flex-end'}
              mr='13%'
              variant='outline'
              rightIcon={<RepeatIcon />}
              >{t('Reset_Text')}</Button>
            </Flex>
           
            
          
          </Center>
        </Flex>

        <TaskAdvice
          instructions={task.taskInstructions}
          pb='0'
          mb='0'
          pt='60px'
        />
      </VStack>
    </Flex>
  )
}
