import React, { useEffect, useState } from 'react'
import { ImageSortingTask } from 'data-models/task-models'
import { Button, Flex, VStack } from '@chakra-ui/react'
import { useFollowUp } from 'providers/FollowUpProvider'
import { TaskAdvice } from '../Common'
import { ImagePlacementImagesContainer } from './ImagePlacementImagesContainer'
import { TaskHeading } from '../Common/TaskHeading'
import { RepeatIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'


export const ImageSortingTaskCmp = () => {
  const { currentTask } = useFollowUp()
  const {t} = useTranslation()
  const task = currentTask as ImageSortingTask
  const [remainingImages, setRemainingImages] = useState(
    task.images as string[],
  )

  const [resetCards,setResetCards] = useState(false)

  const [draggedItem,setDraggedItem] = useState('')

  const isImagePlaced = (task: ImageSortingTask, img: string) => {
    let found = false
  
  
    // if(task.name[1] === 'Sort shapes'){
   
    //   task.answerOptions.forEach((answer,index) => {
     
    //     if (answer.placedAtPoint !== null && img.includes(answer.placedAtPoint+index))
    //       found = true
    //   })
    // }

    // else{
      task.answerOptions.forEach(answer => {
     
        if (answer.placedAtPoint !== null && img.includes(answer.placedAtPoint))
          found = true
      })
   // }
    
  
    return found
  }
  
 
  useEffect(() => {
    
    const newImages = task.images.map((img: string) =>
      isImagePlaced(task, img) ? '' : img,
    )

    setRemainingImages(newImages)
  }, [currentTask])


  const handleReset = () => {
    setResetCards(true)
    setRemainingImages(task.images as string[])
    task.answerOptions.forEach(answer => {
     
     answer.placedAtPoint = null
    })
  }

  
  return (
    <VStack p='5' pb='4' mb='2' w='full' h='full' align='center'>
      <TaskHeading
        headingTitle={task.title}
        description={task.description}
        subtitle={task.subtitle}
      />
      <VStack h='full' w='full'>
        <Flex w='full' h='full' minH='0' >
          <ImagePlacementImagesContainer images={remainingImages} resetCards={resetCards} handleReset={handleReset}/>
          {/* <Button  py='4'
              px='3'
              ml='auto'
            
              fontSize='18px'onClick={handleReset}
              pos={'absolute'}
              right={20}
              bottom={100}
              rightIcon={<RepeatIcon />}
              >{t('Reset_Text')}</Button> */}
        </Flex>
        
        <TaskAdvice minH='unset' instructions={task.taskInstructions} />
        
      </VStack>
    </VStack>
  )
}
